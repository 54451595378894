// Libraries
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import useLoginForm from '@shared/modules/Authentication/hooks/useLoginForm';
import Field from 'modules/App/components/Field';

const Content = Styled.View`
  width: 100%;
  max-width: 600px;
  padding-top: 40px;
  padding-horizontal: ${(props) => (props.desktop ? 100 : 50)}px;
`;

const Logo = Styled.H1`
  ${fontWeight(700)}
  margin-top: 40px;
  color: ${colors.gray.primary};
  letter-spacing: -2;
`;

const Title = Styled.H2`
  ${fontWeight(500)}
  margin-top: 20px;
  color: ${colors.gray.primary};
`;

const TextInput = Styled.TextInput.H7`
`;

const Actions = Styled.View`
  margin-top: 20px;
  flex-direction: row;
`;

const SubmitButton = Styled.LoadingButton`
  height: 40px;
  width: 120px;
`;

const SubmitText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const LoginPage = () => {
  const responsive = useResponsive();
  const {form, submitting, handleSubmit} = useLoginForm({
    onSuccess: () => {
      window.location.href = '/';
    },
  });

  return (
    <ScrollView contentContainerStyle={{alignItems: 'center'}} style={{flex: 1}}>
      <Content {...responsive}>
        <Logo>Supermove</Logo>
        <Title>Welcome back!</Title>
        <Space height={20} />
        <Field
          {...form}
          component={TextInput}
          name={'email'}
          label={'Email'}
          input={{
            placeholder: 'Enter your email',
            style: {
              marginTop: 3,
            },
          }}
        />
        <Space height={20} />
        <Field
          {...form}
          component={TextInput}
          name={'password'}
          label={'Password'}
          input={{
            secureTextEntry: true,
            placeholder: 'Enter your password',
            onSubmitEditing: handleSubmit,
            style: {
              marginTop: 3,
            },
          }}
        />
        <Actions>
          <SubmitButton loading={submitting} onPress={handleSubmit}>
            <SubmitText>Log In</SubmitText>
          </SubmitButton>
        </Actions>
      </Content>
    </ScrollView>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
LoginPage.propTypes = {};

LoginPage.defaultProps = {};

export default LoginPage;
