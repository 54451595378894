// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import JobForm from '@shared/modules/Job/forms/JobForm';
import ProjectForm from '@shared/modules/Project/forms/ProjectForm';

const edit = withFragment(
  (inventory, {creatorId}) => ({
    inventoryId: inventory.id,
    userId: inventory.project.customer.id,
    projectForm: ProjectForm.addJobToProject(inventory.project, {kind: 'REQUEST', creatorId}),
  }),
  gql`
    ${ProjectForm.addJobToProject.fragment}

    fragment CompleteInventoryProjectForm_edit on Inventory {
      id
      project {
        id
        customer {
          id
        }
        ...ProjectForm_addJobToProject
      }
    }
  `,
);

const CompleteInventoryProjectForm = {
  toForm: ({inventoryId, userId, projectForm}) => ({
    inventoryId,
    userId,
    projectForm: ProjectForm.toForm(projectForm),
  }),

  toMutation: ({inventoryId, userId, projectForm}) => ({
    inventoryId,
    userId,

    // This form is used only on the complete inventory page of the customer
    // app. On this page we enforce client side that there is a pickup location.
    // The dropoff location is optional. Here we handle removing the dropoff
    // location if it is empty so that the backend does not throw an error.
    projectForm: ProjectForm.toMutation({
      ...projectForm,
      jobForms: projectForm.jobForms.map((jobForm) => JobForm.handleEmptyLocationForms(jobForm)),
    }),
  }),

  edit,
};

export default CompleteInventoryProjectForm;
