// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useInlineFormMutation,
  useModal,
  useMountEffect,
  useNavigation,
  useQuery,
} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import LargeButton from 'components/LargeButton';
import UploadInventoryTutorialModal from 'modules/Inventory/Help/InventoryTutorial/UploadInventoryTutorialModal';
import OrganizationMessage from 'modules/Inventory/Start/components/OrganizationMessage';

const Container = Styled.View`
  padding-horizontal: 16px;
  background-color: ${colors.gray.background};
`;

const IntroductionSection = Styled.View`
  align-items: center;
`;

const Introduction = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.black};
`;

const PoweredBy = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const ContentContainer = Styled.View`
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 8px;
  padding-horizontal: 16px;
  backgroundColor: ${colors.white};
`;

const ProjectSizeButton = Styled.Touchable`
  background-color: ${colors.blue.accent};
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
`;

const ProjectSizeText = Styled.H7`
  ${fontWeight(700)}
  color=${colors.blue.accentDark};
`;

const PlaceholderText = Styled.H7`
  color: ${colors.gray.tertiary};
`;

const TimeCommittmentContainer = Styled.View`
  background-color: ${colors.orange.accent};
  border-width: 1px;
  border-color: ${colors.orange.status};
  padding: 12px;
  border-radius: 8px;
`;

const TimeCommittmentText = Styled.H7`
  color: ${colors.black};
  ${fontWeight(500)}
`;

const StartInventoryPageContent = ({project, inventory}) => {
  const {navigator, params} = useNavigation();
  const uploadInventoryTutorialModal = useModal();
  const handleNextPage = () => navigator.navigate('NewRoomInventoryPage', {uuid: params.uuid});
  const {submitting, handleSubmit} = useInlineFormMutation({
    name: 'startInventoryProjectForm',
    form: {
      inventoryId: inventory.id,
    },
    mutation: gql`
      mutation useStartInventoryProjectMutation($startInventoryProjectForm: StartInventoryProjectForm!) {
        response: startInventoryProject(startInventoryProjectForm: $startInventoryProjectForm) {
          ${gql.errors}
          inventory {
            id
          }
        }
      }
    `,
    onSuccess: () => {
      if (project.hasCompletedInventoryTutorial) {
        handleNextPage();
      } else {
        uploadInventoryTutorialModal.handleOpen();
      }
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  useMountEffect(() => {
    if (inventory.isVirtualWalkthroughCompleted) {
      navigator.navigate('HomePage');
    }
  });

  return (
    <React.Fragment>
      <ScrollView>
        <Container>
          <Space height={32} />
          <IntroductionSection>
            <Introduction>Let's get you an estimate</Introduction>
            <Space height={4} />
            <PoweredBy>Powered by Supermove</PoweredBy>
          </IntroductionSection>
          <Space height={32} />
          <ContentContainer>
            <Space height={16} />
            <OrganizationMessage organization={project.organization} />
            <Space height={12} />
            <ProjectSizeButton
              onPress={() => navigator.navigate('PrepareInventoryPage', {uuid: params.uuid})}
            >
              {project.size ? (
                <ProjectSizeText>{project.size}</ProjectSizeText>
              ) : (
                <PlaceholderText>Select a size</PlaceholderText>
              )}
              <Icon color={colors.blue.accentDark} size={20} source={Icon.CaretDown} />
            </ProjectSizeButton>
            <Space height={12} />
            <TimeCommittmentContainer>
              <TimeCommittmentText>
                Your virtual walkthrough will take 10 minutes!
              </TimeCommittmentText>
            </TimeCommittmentContainer>
            <Space height={16} />
          </ContentContainer>
          <Space height={24} />
          <LargeButton onPress={handleSubmit} disabled={submitting} submitting={submitting}>
            <LargeButton.Text color={colors.gray.background}>Let's do this</LargeButton.Text>
          </LargeButton>
          <Space height={32} />
        </Container>
      </ScrollView>
      <UploadInventoryTutorialModal
        isOpen={uploadInventoryTutorialModal.isOpen}
        onClose={({completeInventoryTutorialForm}) => {
          if (completeInventoryTutorialForm.hasCompletedInventoryTutorial) {
            handleNextPage();
          } else {
            uploadInventoryTutorialModal.handleClose();
          }
        }}
      />
    </React.Fragment>
  );
};

const StartInventoryPage = () => {
  const {params} = useNavigation();
  const {loading, data} = useQuery(StartInventoryPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.uuid,
      inventoryTutorialKind: 'RECORD_ROOM',
    },
  });

  return (
    <Loading loading={loading}>
      {() => (
        <StartInventoryPageContent project={data.inventory.project} inventory={data.inventory} />
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StartInventoryPage.query = gql`
  ${OrganizationMessage.fragment}
  query StartInventoryPage($uuid: String!, $inventoryTutorialKind: String!) {
    ${gql.query}
    inventory(uuid: $uuid) {
      id
      isVirtualWalkthroughCompleted
      project {
        id
        size
        hasCompletedInventoryTutorial(kind: $inventoryTutorialKind)
        organization {
          id
          ...OrganizationMessage
        }
      }
    }
  }
`;

export default StartInventoryPage;
