export const ClosetTutorialSlides = [
  {
    title: 'Walk-in Closets',
    images: [require('./walkin-closet-1.jpg'), require('./walkin-closet-2.jpg')],
  },
  {
    title: 'Sliding-door Closets',
    images: [require('./sliding-closet-1.jpg'), require('./sliding-closet-2.jpg')],
  },
];

export const AccessPointTutorialSlides = [
  {
    title: 'House Access Point',
    images: [require('./house-access-1.jpg'), require('./house-access-2.jpg')],
  },
  {
    title: 'Apartment Access Point',
    images: [require('./apartment-access-1.jpg'), require('./apartment-access-2.jpg')],
  },
];

export const RecordRoomVideo = require('./record-room-video.mp4');
