/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// App
import Field from 'components/Field';

const RoomNotesContainer = Styled.View`
  padding-horizontal: 16px;
`;

const SectionTitleContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const SectionTitleText = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const SectionTitle = ({children, icon, size}) => {
  return (
    <SectionTitleContainer>
      <Icon color={colors.gray.primary} size={size} source={icon} />
      <Space width={8} />
      <SectionTitleText>{children}</SectionTitleText>
    </SectionTitleContainer>
  );
};

const RoomNotes = ({form}) => {
  return (
    <RoomNotesContainer>
      <Space height={16} />
      <SectionTitle icon={Icon.File} size={16}>
        Notes
      </SectionTitle>
      <Space height={8} />
      <Field
        {...form}
        name={'customerRoomForm.customerNotes'}
        input={{
          placeholder: 'Add special items here',
          multiline: true,
          style: {
            minHeight: 90,
            backgroundColor: colors.white,
            borderColor: colors.gray.border,
            borderWidth: 1,
            borderRadius: 8,
          },
        }}
      />
    </RoomNotesContainer>
  );
};

export default RoomNotes;
