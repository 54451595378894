// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import CustomerInventoryForm from '@shared/modules/Inventory/forms/CustomerInventoryForm';

// App

const useUpdateCustomerInventoryMutation = ({customerInventoryForm, onSuccess, onError}) => {
  const form = useForm({
    initialValues: {
      customerInventoryForm: CustomerInventoryForm.toForm(customerInventoryForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCustomerInventoryMutation.mutation,
    variables: {
      customerInventoryForm: CustomerInventoryForm.toMutation(form.values.customerInventoryForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateCustomerInventoryMutation.mutation = gql`
  mutation useUpdateCustomerInventoryMutation($customerInventoryForm: CustomerInventoryForm!) {
    response: updateCustomerInventory(customerInventoryForm: $customerInventoryForm) {
      ${gql.errors}
      inventory {
        id
      }
    }
  }
`;

export default useUpdateCustomerInventoryMutation;
