// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = withFragment(
  (inventory, {kind}) => ({
    inventoryId: inventory.id,
    projectId: inventory.project.id,
    userId: inventory.project.customer.id,
    kind,
    hasCompletedInventoryTutorial: inventory.project.hasCompletedInventoryTutorial,
  }),
  gql`
    fragment CompleteInventoryTutorialForm_new on Inventory {
      id
      project {
        id
        hasCompletedInventoryTutorial(kind: $inventoryTutorialKind)
        customer {
          id
        }
      }
    }
  `,
);

const CompleteInventoryTutorialForm = {
  toForm: ({inventoryId, projectId, userId, kind, hasCompletedInventoryTutorial}) => ({
    inventoryId,
    projectId,
    userId,
    kind,
    hasCompletedInventoryTutorial,
  }),

  toMutation: ({inventoryId, projectId, userId, kind}) => ({
    inventoryId,
    projectId,
    userId,
    kind,
  }),

  new: _new,
};

export default CompleteInventoryTutorialForm;
