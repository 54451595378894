/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {DateInput, Icon, Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

import Field from 'components/Field';

const FlexibleDateButton = Styled.Touchable`
  flex-direction: row;
  align-items: center;
`;

const FlexibleDateButtonCheckbox = Styled.View`
  height: 20px;
  width: 20px;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${(props) => (props.isChecked ? colors.blue.interactive : colors.gray.tertiary)};
  background-color: ${(props) => (props.isChecked ? colors.blue.interactive : colors.white)};
  align-items: center;
  justify-content: center;
`;

const FlexibleDateButtonText = Styled.H8`
  ${fontWeight(500)}
`;

const MoveDatePreferenceFields = ({form, field, jobForm}) => {
  return (
    <React.Fragment>
      <Field
        {...form}
        component={DateInput}
        name={`${field}.date`}
        input={{
          placeholder: 'MM/DD/YY',
          style: {
            height: 48,
            backgroundColor: colors.gray.background,
            borderWidth: 1,
            borderColor: colors.gray.border,
          },
          setFieldValue: form.setFieldValue,
          // Note(dan) Since we're not supporting submitting date ranges on the
          // customer app we want to always set this boolean to false.
          onChangeDate: () => form.setFieldValue(`${field}.isEstimatedRange`, false),
        }}
      />
      <Space height={8} />
      <FlexibleDateButton
        onPress={() => form.setFieldValue(`${field}.isDateFlexible`, !jobForm.isDateFlexible)}
      >
        <FlexibleDateButtonCheckbox isChecked={jobForm.isDateFlexible}>
          <Icon color={colors.white} size={14} source={Icon.Check} />
        </FlexibleDateButtonCheckbox>
        <Space width={4} />
        <FlexibleDateButtonText>Move date flexible</FlexibleDateButtonText>
      </FlexibleDateButton>
    </React.Fragment>
  );
};

export default MoveDatePreferenceFields;
