/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import ModalScreenHeader from 'components/ModalScreenHeader';

const HintIconContainer = Styled.View`
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.orange.accent}
  border-radius: 8px;
`;

const TutorialHeader = ({title}) => {
  return (
    <ModalScreenHeader
      title={title}
      left={
        <React.Fragment>
          <HintIconContainer>
            <Icon color={colors.orange.status} size={16} source={Icon.Lightbulb} />
          </HintIconContainer>
          <Space width={12} />
        </React.Fragment>
      }
    />
  );
};

export default TutorialHeader;
