/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import HeaderTitle from 'components/HeaderTitle';

const Container = Styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-horizontal: 12px;
  height: 60px;
  background-color: ${colors.white};
`;

const ScreenHeader = ({title}) => {
  return (
    <Container>
      <HeaderTitle>{title}</HeaderTitle>
    </Container>
  );
};

export default ScreenHeader;
