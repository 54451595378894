/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useNavigation} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import useDeleteRoomMutation from '@shared/modules/Inventory/hooks/useDeleteRoomMutation';
import Modal from 'components/Modal';

const Title = Styled.H6`
  ${fontWeight(700)}
`;

const Subtitle = Styled.H7`
`;

const Actions = Styled.View`
  flex-direction: row;
`;

const ActionButton = Styled.Button`
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-vertical: 14;
  border-radius: 100px;
  background-color: ${(props) => props.background}
`;

const ActionButtonText = Styled.H6`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const RoomConfirmDiscardModal = ({room, isOpen, onClose}) => {
  const {navigator} = useNavigation();
  const {submitting, handleSubmit} = useDeleteRoomMutation({
    roomId: room.id,
    onSuccess: () => {
      onClose();
      navigator.goBack();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Icon color={colors.red.warning} size={22} source={Icon.Trash} />
      <Space height={16} />
      <Title>{`Discard ${room.name}?`}</Title>
      <Space height={28} />
      <Subtitle>{`If you discard ${room.name} now, you will lose any recorded videos and changes you've made.`}</Subtitle>
      <Space height={28} />
      <Actions>
        <ActionButton
          onPress={handleSubmit}
          background={colors.gray.tertiary}
          disabled={submitting}
        >
          <ActionButtonText color={colors.gray.background}>Discard</ActionButtonText>
        </ActionButton>
        <Space width={16} />
        <ActionButton onPress={onClose} background={colors.orange.status} disabled={submitting}>
          <ActionButtonText color={colors.white}>Keep</ActionButtonText>
        </ActionButton>
      </Actions>
    </Modal>
  );
};

export default RoomConfirmDiscardModal;
