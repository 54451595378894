/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Modal as BaseModal, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
  justify-content: center;
  padding: 16px;
`;

const Content = Styled.View`
  background-color: ${colors.white};
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  border-radius: 20px;
  padding: 28px;
`;

const Modal = ({isOpen, onClose, children}) => {
  return (
    <BaseModal.Content isOpen={isOpen} onClose={onClose}>
      <Container>
        <Content>{children}</Content>
      </Container>
    </BaseModal.Content>
  );
};

export default Modal;
