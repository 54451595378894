// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

// App
import LocationForm from '@shared/modules/Location/forms/LocationForm';
import ContactForm from '@shared/modules/User/forms/ContactForm';

const _contactNamesToClientName = (names) => {
  return names
    .map((name) => name.trim())
    .join(' ')
    .trim();
};

const _new = ({organizationId, isOkayDuplicatePhoneNumber}) => ({
  clientId: null,
  coordinatorId: null,
  organizationId,
  salespersonId: null,
  name: '',
  referralDetails: '',
  referralSource: '',
  billingLocationForm: LocationForm.new(),
  primaryContactForm: ContactForm.new({organizationId}),
  primaryLocationForm: LocationForm.new(),
  notes: '',
  isOkayDuplicatePhoneNumber,

  // Private
  clientUuid: '',
  isShowingName: false,
  isSameAddress: true,
  warningMessage: '',
  isDuplicatePhoneNumber: false,
  externalIntegrationCustomerId: null,
});

const newFromJobRequest = withFragment(
  (jobRequest, {viewerId}) => ({
    clientId: '',
    organizationId: jobRequest.organizationId,
    salespersonId: jobRequest.bookedById || viewerId,
    name: '',
    referralDetails: jobRequest.referralDetails ? jobRequest.referralDetails : '',
    referralSource: jobRequest.referralSource ? jobRequest.referralSource : '',
    billingLocationForm: LocationForm.new(),
    primaryContactForm: ContactForm.edit(jobRequest.customer),
    primaryLocationForm: LocationForm.new(),
    notes: '',
    isOkayDuplicatePhoneNumber: true,

    // Private
    clientUuid: '',
    isShowingName: false,
    isSameAddress: true,
    warningMessage: '',
    isDuplicatePhoneNumber: false,
    externalIntegrationCustomerId: null,
  }),
  gql`
    ${ContactForm.edit.fragment}

    fragment ClientForm_newFromJobRequest on JobRequest {
      id
      bookedById
      organizationId
      referralDetails
      referralSource
      customer {
        id
        ...ContactForm_edit
      }
    }
  `,
);

const newFromProject = withFragment(
  (project) => {
    const job = _.get(project, 'activeJobs.0');
    return {
      clientId: null,
      organizationId: project.organizationId,
      salespersonId: project.bookedById,
      name: job ? job.name : project.customer.fullName,
      referralDetails: project.referralDetails,
      referralSource: project.referralSource,
      billingLocationForm: LocationForm.new(),
      primaryContactForm: ContactForm.edit(project.customer),
      primaryLocationForm: LocationForm.new(),
      notes: '',
      isOkayDuplicatePhoneNumber: true,

      // Private
      clientUuid: '',
      isShowingName: job ? job.name !== project.customer.fullName : false,
      isSameAddress: true,
      isDuplicatePhoneNumber: false,
      externalIntegrationCustomerId: null,
    };
  },
  gql`
    ${ContactForm.edit.fragment}

    fragment ClientForm_newFromProject on Project {
      id
      bookedById
      organizationId
      referralDetails
      referralSource
      activeJobs {
        id
        name
      }
      customer {
        id
        fullName
        ...ContactForm_edit
      }
    }
  `,
);

const edit = withFragment(
  (client, isLeadForm = false) => ({
    clientId: client.id,
    coordinatorId: client.coordinatorId,
    organizationId: client.organizationId,
    salespersonId: client.salespersonId,
    name: client.name,
    referralDetails: client.referralDetails ? client.referralDetails : '',
    referralSource: client.referralSource ? client.referralSource : '',
    billingLocationForm: client.billingLocation
      ? LocationForm.edit(client.billingLocation)
      : LocationForm.new({clientId: client.id}),
    primaryContactForm: ContactForm.edit(client.primaryContact),
    primaryLocationForm: client.primaryLocation
      ? LocationForm.edit(client.primaryLocation)
      : LocationForm.new({clientId: client.id}),
    notes: client.notes ? client.notes : '',
    isOkayDuplicatePhoneNumber: true,

    // Private
    clientUuid: client.uuid,
    isShowingName: isLeadForm || client.name !== client.primaryContact.fullName,
    isSameAddress: !client.billingLocation,
    warningMessage: '',
    isDuplicatePhoneNumber: false,
    externalIntegrationCustomerId: client.latestExternalIntegrationCustomer?.id,
  }),
  gql`
    ${ContactForm.edit.fragment}
    ${LocationForm.edit.fragment}

    fragment ClientForm_edit on Client {
      id
      uuid
      coordinatorId
      organizationId
      salespersonId
      name
      referralDetails
      referralSource
      notes
      billingLocation {
        id
        ...LocationForm_edit
      }
      primaryLocation {
        id
        ...LocationForm_edit
      }
      primaryContact {
        id
        fullName
        ...ContactForm_edit
      }
      latestExternalIntegrationCustomer {
        id
      }
    }
  `,
);

const toForm = ({
  clientId,
  coordinatorId,
  organizationId,
  salespersonId,
  name,
  referralDetails,
  referralSource,
  billingLocationForm,
  primaryContactForm,
  primaryLocationForm,
  notes,
  isOkayDuplicatePhoneNumber,
  clientUuid,
  isShowingName,
  isSameAddress,
  warningMessage,
  isDuplicatePhoneNumber,
  externalIntegrationCustomerId,
}) => ({
  clientId,
  coordinatorId,
  organizationId,
  salespersonId,
  name,
  referralDetails,
  referralSource,
  billingLocationForm: LocationForm.toForm(billingLocationForm),
  primaryContactForm: ContactForm.toForm(primaryContactForm),
  primaryLocationForm: LocationForm.toForm(primaryLocationForm),
  notes,
  isOkayDuplicatePhoneNumber,

  // Private
  clientUuid,
  isShowingName,
  isSameAddress,
  warningMessage,
  isDuplicatePhoneNumber,
  externalIntegrationCustomerId: externalIntegrationCustomerId
    ? _.toNumber(externalIntegrationCustomerId)
    : null,
});

const toMutation = ({
  clientId,
  coordinatorId,
  organizationId,
  salespersonId,
  name,
  referralDetails,
  referralSource,
  billingLocationForm,
  primaryContactForm,
  primaryLocationForm,
  notes,
  isOkayDuplicatePhoneNumber,
  isShowingName,
  externalIntegrationCustomerId,
}) => ({
  clientId: clientId || null,
  coordinatorId,
  organizationId,
  salespersonId,
  name: isShowingName ? name : _contactNamesToClientName(primaryContactForm.names),
  referralDetails,
  referralSource,
  billingLocationForm: LocationForm.toMutation(billingLocationForm),
  primaryContactForm: ContactForm.toMutation(primaryContactForm),
  primaryLocationForm: LocationForm.toMutation(primaryLocationForm),
  notes,
  isOkayDuplicatePhoneNumber,
  externalIntegrationCustomerId,
});

const ClientForm = {
  new: _new,
  newFromJobRequest,
  newFromProject,
  edit,
  toForm,
  toMutation,
};

export default ClientForm;
