// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const edit = withFragment(
  (room) => ({
    roomId: room.id,
    customerNotes: room.customerNotes,
    canSkipPhotos: false,

    // Private
    isUploadingPhoto: false,
    isUploadingVideo: false,
  }),
  gql`
    fragment CustomerRoomForm_edit on Room {
      id
      customerNotes
    }
  `,
);

const toForm = ({roomId, customerNotes, canSkipPhotos, isUploadingPhoto, isUploadingVideo}) => ({
  roomId,
  customerNotes,
  canSkipPhotos,

  // Private
  isUploadingPhoto,
  isUploadingVideo,
});

const toMutation = ({roomId, customerNotes, canSkipPhotos}) => ({
  roomId,
  customerNotes,
  canSkipPhotos,
});

const CustomerRoomForm = {
  edit,
  toForm,
  toMutation,
};

export default CustomerRoomForm;
