/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Text = Styled.H6`
  color: ${colors.black};
  ${fontWeight(700)}
`;

const HeaderTitle = ({children}) => {
  return <Text>{children}</Text>;
};

export default HeaderTitle;
