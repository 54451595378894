/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

// App
import CheckCircleIcon from 'components/CheckCircleIcon';
import Modal from 'components/Modal';

const Message = Styled.H7`
  ${fontWeight(700)}
`;

const ReminderSection = Styled.View`
  background-color: ${colors.orange.accent};
  border-radius: 8px;
`;

const ReminderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 6px;
  background-color: ${colors.orange.accent};
`;

const LocationText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Actions = Styled.View`
  flex-direction: row;
`;

const ActionButton = Styled.Button`
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-vertical: 14;
  border-radius: 100px;
  background-color: ${(props) => props.background}
`;

const ActionButtonText = Styled.H6`
  ${fontWeight(700)}
  color: ${(props) => props.color};
`;

const REMINDERS = ['Hallway', 'Garage', 'Attic', 'Backyard', 'Outdoor Units'];

const Reminder = ({location}) => {
  return (
    <ReminderContainer>
      <Space width={16} />
      <CheckCircleIcon isChecked size={16} checkSize={10} />
      <Space width={8} />
      <LocationText>{location}</LocationText>
    </ReminderContainer>
  );
};

const DoneRecordingConfirmationModal = ({isOpen, onCloseModal, onContinue}) => {
  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      <Icon color={colors.orange.status} size={22} source={Icon.Bullhorn} />
      <Space height={28} />
      <Message>
        We want to get you the most accurate estimate possible. Did you remember to record the
        following places?
      </Message>
      <Space height={28} />
      <ReminderSection>
        <Space height={10} />
        {REMINDERS.map((location, index) => (
          <Reminder location={location} key={index} />
        ))}
        <Space height={10} />
      </ReminderSection>
      <Space height={28} />
      <Actions>
        <ActionButton onPress={onCloseModal} background={colors.gray.tertiary}>
          <ActionButtonText color={colors.gray.background}>No, go back</ActionButtonText>
        </ActionButton>
        <Space width={16} />
        <ActionButton onPress={onContinue} background={colors.orange.status}>
          <ActionButtonText color={colors.white}>Yes, continue</ActionButtonText>
        </ActionButton>
      </Actions>
    </Modal>
  );
};

export default DoneRecordingConfirmationModal;
