/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  z-index: ${(props) => 100 - props.index};
`;

const Input = Styled.TextInput.H7`
  background-color: ${colors.gray.border};
  border-color: ${colors.gray.border};
  padding: 16px;
  ${fontWeight(500)}
`;

const ValidationError = Styled.H7`
  margin-top: 5px;
  color: ${colors.red.warning};
`;

const Field = ({
  index = 0,
  name,
  touched,
  errors,
  values,
  component: InputComponent = Input,
  input = {},
  handleBlur,
  handleChange,
  style,
}) => {
  return (
    <Container index={index} style={style}>
      <InputComponent
        {...input}
        name={name}
        value={_.get(values, name)}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholderTextColor={colors.gray.tertiary}
      />
      {_.get(touched, name) && _.get(errors, name) && (
        <ValidationError>{_.get(errors, name)}</ValidationError>
      )}
    </Container>
  );
};

export default Field;
