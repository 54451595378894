/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import BackButton from 'components/BackButton';
import HeaderTitle from 'components/HeaderTitle';
import InventoryHelpButton from 'modules/Inventory/components/InventoryHelpButton';

const Container = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-horizontal: 12px;
  height: 60px;
  background-color: ${colors.white};
`;

const BackContainer = Styled.View`
  width: 64px;
  padding-left: 4px;
`;

const InventoryHeader = ({title, inventory}) => {
  return (
    <Container>
      <BackContainer>
        <BackButton />
      </BackContainer>
      <HeaderTitle>{title}</HeaderTitle>
      <InventoryHelpButton inventory={inventory} />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryHeader.fragment = gql`
  ${InventoryHelpButton.fragment}

  fragment InventoryHeader on Inventory {
    id
    ...InventoryHelpButton
  }
`;

export default InventoryHeader;
