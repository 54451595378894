/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {FileDragInput, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useState} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';
import useUploadAttachmentForm from '@shared/modules/File/hooks/useUploadAttachmentForm';
import VideoViewer from 'components/VideoViewer';

const Container = Styled.View`
  padding-horizontal: 16px;
`;

// This is because the FileDragInput inserts an extra div which affects styling.
const UploadContainer = Styled.View`
  flex: 1;
`;

const VideoContainer = Styled.View`
  min-height: 180px;
`;

const LoadingContainer = Styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  border-color: ${colors.gray.border};
  border-width: 1px;
  border-radius: 4px;
`;

const Indicator = Styled.Loading`
`;

const RerecordColumn = Styled.View`
  align-items: flex-end;
`;

const RerecordButtonContainer = Styled.View`
  background-color: ${(props) => (props.disabled ? colors.gray.border : colors.blue.accent)}
  width: 92px;
  padding: 6px;
  align-items: center;
  border-radius: 20px;
`;

const RerecordButtonText = Styled.H8`
  color: ${(props) => (props.disabled ? colors.gray.tertiary : colors.blue.accentDark)}
  ${fontWeight(700)}
`;

const EmptyContainer = Styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  border-color: ${colors.gray.border};
  border-width: 1px;
  border-radius: 4px;
`;

const EmptyMessage = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
  text-align: center;
`;

const UploadRoomVideoInput = ({form, children, handleSubmit, disabled}) => {
  return (
    <FileDragInput
      onFilesChange={(files) => {
        const file = files[0];
        form.setFieldValue(`uploadFileForm.requestUploadFileForm.mimetype`, file.type);
        form.setFieldValue(`uploadFileForm.requestUploadFileForm.filename`, file.name);
        form.setFieldValue(`uploadFileForm.file`, file);
        handleSubmit();
      }}
      accept={'video/*'}
      disabled={disabled}
    >
      {({isDragActive}) => children}
    </FileDragInput>
  );
};

const VideoLoading = () => {
  return (
    <VideoContainer>
      <LoadingContainer>
        <Indicator size={'small'} color={colors.gray.secondary} />
      </LoadingContainer>
    </VideoContainer>
  );
};

const UploadInput = ({form, handleUpload}) => {
  return (
    <UploadRoomVideoInput form={form} handleSubmit={handleUpload}>
      <UploadContainer>
        <VideoContainer>
          <EmptyContainer>
            <Icon size={24} color={colors.blue.interactive} source={Icon.Video} />
            <Icon
              color={colors.white}
              size={10}
              source={Icon.Plus}
              style={{marginTop: -17, marginLeft: -9}}
            />
            <Space height={12} />
            <EmptyMessage>Press here to record</EmptyMessage>
          </EmptyContainer>
        </VideoContainer>
      </UploadContainer>
    </UploadRoomVideoInput>
  );
};

const RerecordButton = ({form, isDisabled, handleUpload}) => {
  return (
    <RerecordColumn>
      <UploadRoomVideoInput form={form} disabled={isDisabled} handleSubmit={handleUpload}>
        <RerecordButtonContainer disabled={isDisabled}>
          <RerecordButtonText disabled={isDisabled}>Re-record</RerecordButtonText>
        </RerecordButtonContainer>
      </UploadRoomVideoInput>
    </RerecordColumn>
  );
};

const RoomRecordingContent = ({
  room,
  viewer,
  refetch,
  onUploadStart,
  onUploadSuccess,
  onUploadError,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const uploadFileForm = UploadFileForm.new({
    organizationId: room.inventory.project.organization.id,
    creatorId: viewer.id,
    projectId: room.inventory.project.id,
    roomId: room.id,
    inventoryId: room.inventory.id,
    attachmentCategoryKinds: ['ROOM_VIDEO'],
  });
  const {form, handleSubmit} = useUploadAttachmentForm({
    uploadFileForm,
    onSuccess: () => {
      refetch();
      onUploadSuccess();
      setIsUploading(false);
    },
    onError: (error) => {
      console.log(error);
      onUploadError();
      setIsUploading(false);
    },
  });

  const file = room.latestVideoAttachment?.file;
  const handleUpload = () => {
    setIsUploading(true);
    onUploadStart();
    handleSubmit();
  };

  return (
    <Container>
      <Space height={16} />
      {isUploading ? (
        <VideoLoading />
      ) : file ? (
        <VideoViewer key={file.id} file={file} style={{borderRadius: 4}} />
      ) : (
        <UploadInput form={form} handleUpload={handleUpload} />
      )}
      <Space height={8} />
      <RerecordButton form={form} isDisabled={!file || isUploading} handleUpload={handleUpload} />
      <Space height={8} />
    </Container>
  );
};

const RoomRecording = ({room, onUploadStart, onUploadSuccess, onUploadError}) => {
  const {data, refetch} = useQuery(RoomRecording.query, {
    fetchPolicy: 'network-only',
    variables: {
      roomUuid: room.uuid,
    },
  });

  if (!data) {
    return null;
  }

  return (
    <RoomRecordingContent
      room={data.room}
      viewer={data.viewer}
      refetch={refetch}
      onUploadStart={onUploadStart}
      onUploadSuccess={onUploadSuccess}
      onUploadError={onUploadError}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RoomRecording.query = gql`
  ${VideoViewer.fragment}
  query RoomRecording($roomUuid: String!) {
    ${gql.query}
    viewer {
      id
    }
    room(uuid: $roomUuid) {
      id
      name
      latestVideoAttachment {
        id
        file {
          id
          playbackUrl
          ...VideoViewer
        }
      }
      inventory {
        id
        project {
          id
          organization {
            id
          }
        }
      }
    }
  }
`;

RoomRecording.fragment = gql`
  fragment RoomRecording on Room {
    id
    uuid
  }
`;

export default RoomRecording;
