// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import SupermoveLogo from 'components/assets/supermove-logo.png';

const Image = Styled.Image`
`;

const OrganizationPhoto = ({organization, style}) => {
  return (
    <Image
      style={style}
      source={{
        uri: organization.virtualWalkthroughPhotoFile
          ? organization.virtualWalkthroughPhotoFile.downloadUrl
          : SupermoveLogo,
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationPhoto.fragment = gql`
  fragment OrganizationPhoto on Organization {
    id
    virtualWalkthroughPhotoFile {
      id
      downloadUrl
    }
  }
`;

export default OrganizationPhoto;
