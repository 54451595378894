/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

const RoomTypesListContainer = Styled.View`
  flex: 1;
  padding-horizontal: 12px;
  padding-vertical: 12px;
`;

const RoomContainer = Styled.View`
  flex: 0.5;
  flex-direction: row;
`;

const RoomButton = Styled.Touchable`
  flex: 1;
  height: 60px;
  border-width: 1px;
  border-color: ${(props) => (props.isSelected ? colors.orange.status : colors.gray.border)};
  border-radius: 8px;
  justify-content: center;
  background-color: ${(props) => (props.isSelected ? colors.orange.accent : colors.white)};
`;

const RoomText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.black};
  padding-horizontal: 12px;
`;

const getRoomName = ({inventory, roomType}) => {
  const rooms = _.filter(inventory.rooms, (room) => {
    return String(room.roomTypeId) === String(roomType.id);
  });

  // Ex. Bedroom 1
  return `${roomType.name} ${rooms.length + 1}`;
};

const RoomItem = ({roomType, roomName, form, disabled}) => {
  const selectRoom = () => {
    form.setFieldValue('roomForm.roomTypeId', roomType.id);
    form.setFieldValue('roomForm.name', roomName);
  };

  return (
    <RoomContainer>
      <Space width={4} />
      <RoomButton
        onPress={selectRoom}
        isSelected={roomType.id === form.values.roomForm.roomTypeId}
        disabled={disabled}
      >
        <RoomText>{roomType.name}</RoomText>
      </RoomButton>
      <Space width={4} />
    </RoomContainer>
  );
};

const RoomTypesList = ({roomTypes, inventory, form, submitting}) => {
  return (
    <RoomTypesListContainer>
      <FlatList
        data={roomTypes}
        bounces={false}
        renderItem={({item: roomType}) => (
          <RoomItem
            roomType={roomType}
            form={form}
            inventoryId={inventory.id}
            disabled={submitting}
            roomName={getRoomName({inventory, roomType})}
          />
        )}
        numColumns={2}
        ItemSeparatorComponent={() => <Space height={12} />}
        extraData={form.values.roomForm.roomTypeId}
        keyExtractor={(roomType) => roomType.id}
      />
    </RoomTypesListContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RoomTypesList.fragment = gql`
  fragment RoomTypesList on RoomType {
    id
    name
  }
`;

export default RoomTypesList;
