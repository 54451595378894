/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';

// App
import MultipleChoiceOption from 'components/MultipleChoiceOption';

const OptionsContainer = Styled.View`
  padding-horizontal: 12px;
`;

const MultipleChoiceOptionContainer = Styled.View`
  flex: 0.5;
  flex-direction: row;
`;

const OptionsRow = Styled.View`
  flex-direction: row;
  margin-bottom: 8px;
`;

const BuildingSizes = ({sizeOptions, form, field}) => {
  const projectSize = _.get(form.values, field);
  return (
    <OptionsContainer>
      {_.chunk(sizeOptions, 2).map((sizeOptionsPair, index) => {
        return (
          <OptionsRow key={index}>
            {sizeOptionsPair.map((size) => {
              return (
                <MultipleChoiceOptionContainer key={size.value}>
                  <Space width={4} />
                  <MultipleChoiceOption
                    height={42}
                    isSelected={size.value === projectSize}
                    onPress={() => form.setFieldValue(field, size.value)}
                  >
                    {size.label}
                  </MultipleChoiceOption>
                  <Space width={4} />
                </MultipleChoiceOptionContainer>
              );
            })}
          </OptionsRow>
        );
      })}
    </OptionsContainer>
  );
};

export default BuildingSizes;
