/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import InventoryReviewForm from '@shared/modules/Inventory/forms/InventoryReviewForm';
import useCreateInventoryReviewMutation from '@shared/modules/Inventory/hooks/useCreateInventoryReviewMutation';
import LargeButton from 'components/LargeButton';
import ProgressBar from 'components/ProgressBar';
import InventoryReviewFields from 'modules/Inventory/InventoryReview/New/components/InventoryReviewFields';
import OrganizationMessage from 'modules/Inventory/InventoryReview/New/components/OrganizationMessage';
import InventoryHeader from 'modules/Inventory/components/InventoryHeader';

const BodyContainer = Styled.View`
  padding-horizontal: 16px;
`;

const NewInventoryReviewInventoryContent = ({viewer, inventory}) => {
  const {navigator} = useNavigation();
  const inventoryReviewForm = InventoryReviewForm.new({
    userId: viewer.id,
    inventoryId: inventory.id,
  });
  const {form, submitting, handleSubmit} = useCreateInventoryReviewMutation({
    inventoryReviewForm,
    onSuccess: () => {
      navigator.navigate('HomePage');
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <ScrollView>
      <InventoryHeader title={'Virtual Walkthrough'} inventory={inventory} />
      <ProgressBar progressAmount={1} />
      <BodyContainer>
        <Space height={16} />
        <OrganizationMessage organization={inventory.project.organization} />
        <Space height={16} />
        <InventoryReviewFields form={form} />
        <Space height={24} />
        <LargeButton
          onPress={handleSubmit}
          disabled={!form.values.inventoryReviewForm.rating || submitting}
        >
          <LargeButton.Text color={colors.gray.background}>View move portal</LargeButton.Text>
        </LargeButton>
      </BodyContainer>
      <Space height={32} />
    </ScrollView>
  );
};

const NewInventoryReviewInventoryPage = () => {
  const {params} = useNavigation();
  const {loading, data} = useQuery(NewInventoryReviewInventoryPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.uuid,
    },
  });

  return (
    <Loading loading={loading}>
      {() => <NewInventoryReviewInventoryContent viewer={data.viewer} inventory={data.inventory} />}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
NewInventoryReviewInventoryPage.query = gql`
  ${OrganizationMessage.fragment}
  ${InventoryHeader.fragment}

  query NewInventoryReviewInventoryPage($uuid: String!) {
    ${gql.query}

    viewer {
      id
    }
    inventory(uuid: $uuid) {
      id
      project {
        id
        organization {
          id
          ...OrganizationMessage
        }
      }
      ...InventoryHeader
    }
  }
`;

export default NewInventoryReviewInventoryPage;
