/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import Line from 'components/Line';
import ScreenHeader from 'components/ScreenHeader';
import ContactUs from 'modules/Home/components/ContactUs';
import PremoveTasks from 'modules/Home/components/PremoveTasks';

const ContentContainer = Styled.View`
`;

const HomeContent = ({viewer}) => {
  return (
    <ContentContainer>
      <ScreenHeader title={'Move Portal'} />
      <Line />
      <ContactUs organization={viewer.organization} />
      <Line />
      <Space height={16} />
      <Line />
      <PremoveTasks />
      <Line />
    </ContentContainer>
  );
};

const HomePage = () => {
  const {loading, data} = useQuery(HomePage.query, {
    fetchPolicy: 'network-only',
  });

  return <Loading loading={loading}>{() => <HomeContent viewer={data.viewer} />}</Loading>;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
HomePage.query = gql`
  ${ContactUs.fragment}

  query HomePage {
    ${gql.query}
    viewer {
      id
      organization {
        id
        ...ContactUs
      }
    }
  }
`;

export default HomePage;
