/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const CloseIconContainer = Styled.Touchable`
`;

const CloseButton = ({onPress}) => {
  return (
    <CloseIconContainer onPress={onPress}>
      <Icon color={colors.black} size={20} source={Icon.Times} />
    </CloseIconContainer>
  );
};

export default CloseButton;
