// Libraries
import React from 'react';

// Supermove
import {SupermoveApp, MaintenancePage} from '@supermove/app';
import {StatusBar, UpdateChecker} from '@supermove/components';
import {Environment} from '@supermove/sdk';
import {colors} from '@supermove/styles';

// App
import {AppInfo} from 'config';
import ErrorModal from 'modules/App/components/ErrorModal';

const App = ({router: Router}) => {
  return Environment.get('SHOW_MAINTENANCE_PAGE') ? (
    <MaintenancePage />
  ) : (
    <React.Fragment>
      <StatusBar barStyle={'light-content'} backgroundColor={colors.black} />
      <ErrorModal
        trigger={({handleOpen}) => (
          <SupermoveApp
            name={'Customer'}
            version={AppInfo.getVersion()}
            buildNumber={AppInfo.getBuildNumber()}
            onError={() => handleOpen()}
          >
            {() => (
              <UpdateChecker showLoading name={'Customer'} buildNumber={AppInfo.getBuildNumber()}>
                {({isSkipped, isVisible, isRequired, onSkip}) =>
                  isVisible && !isSkipped ? (
                    // TODO(mark): Add an UpdateAppView component and render here.
                    <Router />
                  ) : (
                    <Router />
                  )
                }
              </UpdateChecker>
            )}
          </SupermoveApp>
        )}
      />
    </React.Fragment>
  );
};

export default App;
