/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import HeaderTitle from 'components/HeaderTitle';

const Container = Styled.View`
  height: 60px;
  padding-horizontal: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white};
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const LeftSideContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const RightSideContainer = Styled.View`
  flex-direction: row;
`;

const ModalScreenHeader = ({title, left, right}) => {
  return (
    <Container>
      <LeftSideContainer>
        {left}
        <HeaderTitle>{title}</HeaderTitle>
      </LeftSideContainer>
      <RightSideContainer>
        {right}
        <Space width={2} />
      </RightSideContainer>
    </Container>
  );
};

export default ModalScreenHeader;
