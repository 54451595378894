// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import RoomForm from '@shared/modules/Inventory/forms/RoomForm';
import useCreateRoomMutation from '@shared/modules/Inventory/hooks/useCreateRoomMutation';
import ModalScreenHeader from 'components/ModalScreenHeader';
import RoomTypesList from 'modules/Inventory/Room/New/components/RoomTypesList';
import InventoryHelpButton from 'modules/Inventory/components/InventoryHelpButton';

const ContentContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const MainSection = Styled.View`
  flex: 1;
`;

const ButtonSection = Styled.View`
  padding-horizontal: 16px;
`;

const AddRoomButton = Styled.Touchable`
  background-color: ${(props) => (props.disabled ? colors.gray.border : colors.blue.interactive)};
  border-radius: 100px;
  padding: 20px;
  align-items: center;
`;

const AddRoomButtonText = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const DoneButton = Styled.Touchable`
  align-items: center;
  background-color: ${colors.alpha(colors.green.status, 0.1)};
  padding: 12px;
  border-radius: 30px;
`;

const DoneButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.green.status};
`;

const NewRoomInventoryContent = ({roomTypes, inventory}) => {
  const {params, navigator} = useNavigation();
  const roomForm = RoomForm.new({inventoryId: inventory.id});
  const {form, submitting, handleSubmit} = useCreateRoomMutation({
    roomForm,
    onSuccess: ({room}) => {
      navigator.navigate('ShowRoomInventoryPage', {
        uuid: params.uuid,
        roomUuid: room.uuid,
        action: 'create',
      });
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <ContentContainer>
      <MainSection>
        <ModalScreenHeader
          title={'Select Room Type'}
          right={<InventoryHelpButton inventory={inventory} />}
        />
        <RoomTypesList
          roomTypes={roomTypes}
          inventory={inventory}
          form={form}
          submitting={submitting}
        />
      </MainSection>
      <ButtonSection>
        <AddRoomButton
          disabled={!form.values.roomForm.roomTypeId || submitting}
          onPress={handleSubmit}
        >
          <AddRoomButtonText>Add Room</AddRoomButtonText>
        </AddRoomButton>
        <Space height={12} />
        {inventory.rooms.length > 0 && (
          <React.Fragment>
            <DoneButton
              onPress={() => navigator.navigate('ListRoomInventoryPage', {uuid: params.uuid})}
            >
              <DoneButtonText>{`Done? Review your ${pluralize(
                'room',
                inventory.rooms.length,
                true,
              )}`}</DoneButtonText>
            </DoneButton>
          </React.Fragment>
        )}
        <Space height={16} />
      </ButtonSection>
    </ContentContainer>
  );
};

const NewRoomInventoryPage = () => {
  const {params} = useNavigation();
  const {loading, data} = useQuery(NewRoomInventoryPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.uuid,
    },
  });

  return (
    <Loading alwaysUpdate={loading} loading={loading}>
      {() => {
        data.inventory.rooms = data.inventory.rooms.filter((room) => room.name !== 'Access Point');
        const {project} = data.inventory;
        const {roomTypes} = project.projectType.defaultInventoryLibrary;
        return <NewRoomInventoryContent roomTypes={roomTypes} inventory={data.inventory} />;
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
NewRoomInventoryPage.query = gql`
  ${RoomTypesList.fragment}
  ${InventoryHelpButton.fragment}

  query NewRoomInventoryPage($uuid: String!) {
    ${gql.query}

    inventory(uuid: $uuid) {
      id
      rooms {
        id
        roomTypeId
        name
      }
      project {
        id
        organization {
          id
          roomTypes {
            id
            ...RoomTypesList
          }
        }
        projectType {
          id
          defaultInventoryLibrary {
            id
            roomTypes {
              id
              ...RoomTypesList
            }
          }
        }
      }
      ...InventoryHelpButton
    }
  }
`;

export default NewRoomInventoryPage;
