/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {ExternalLink, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import OrganizationPhoto from 'components/OrganizationPhoto';

const Container = Styled.View`
  background-color: ${colors.gray.background};
  padding-vertical: 16px;
  flex-direction: row;
  align-items: center;
`;

const ContactInfo = Styled.View`
  flex-direction: column;
  flex: 1;
`;

const ContactPhone = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.black};
`;

const ContactMessage = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const ContactUs = ({organization}) => {
  return (
    <Container>
      <Space width={24} />
      <OrganizationPhoto
        organization={organization}
        style={{
          height: 36,
          width: 36,
          borderRadius: 18,
        }}
      />
      <Space width={16} />
      <ContactInfo>
        <ContactPhone numberOfLines={1}>{Phone.display(organization.phoneNumber)}</ContactPhone>
        <ExternalLink url={`tel:${organization.phoneNumber}`}>
          <ContactMessage>Contact us if you have any questions!</ContactMessage>
        </ExternalLink>
      </ContactInfo>
      <Space width={24} />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ContactUs.fragment = gql`
  ${OrganizationPhoto.fragment}

  fragment ContactUs on Organization {
    id
    phoneNumber
    ...OrganizationPhoto
  }
`;

export default ContactUs;
