// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

const _new = ({inventoryId}) => ({
  inventoryId,
  accessPointHasElevator: false,
  accessPointHasStairs: false,
  accessPointHasNone: false,
  accessPointWalkTime: '',
  accessPointWalkTimeDetails: '',

  // Private
  isUploadingPhoto: false,
});

const edit = withFragment(
  (inventory) => ({
    inventoryId: inventory.id,
    accessPointHasElevator: inventory.accessPointHasElevator,
    accessPointHasStairs: inventory.accessPointHasStairs,
    accessPointHasNone: false,
    accessPointWalkTime: inventory.accessPointWalkTime,
    accessPointWalkTimeDetails: inventory.accessPointWalkTimeDetails,

    // Private
    isUploadingPhoto: false,
  }),
  gql`
    fragment CustomerInventoryForm_edit on Inventory {
      id
      accessPointHasElevator
      accessPointHasStairs
      accessPointWalkTime
      accessPointWalkTimeDetails
    }
  `,
);

const toForm = ({
  inventoryId,
  accessPointHasElevator,
  accessPointHasStairs,
  accessPointHasNone,
  accessPointWalkTime,
  accessPointWalkTimeDetails,

  // Private
  isUploadingPhoto,
}) => ({
  inventoryId,
  accessPointHasElevator,
  accessPointHasStairs,
  accessPointHasNone,
  accessPointWalkTime,
  accessPointWalkTimeDetails,
  isUploadingPhoto,
});

const toMutation = ({
  inventoryId,
  accessPointHasElevator,
  accessPointHasStairs,
  accessPointWalkTime,
  accessPointWalkTimeDetails,
}) => ({
  inventoryId,
  accessPointHasElevator,
  accessPointHasStairs,
  accessPointWalkTime,
  accessPointWalkTimeDetails,
});

const CustomerInventoryForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default CustomerInventoryForm;
