// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useInlineFormMutation, useNavigation, useState, useQuery} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import CloseButton from 'components/CloseButton';
import LargeButton from 'components/LargeButton';
import Line from 'components/Line';
import ModalScreenHeader from 'components/ModalScreenHeader';
import BuildingSizes from 'modules/Inventory/Prepare/components/BuildingSizes';
import BuildingTypes from 'modules/Inventory/Prepare/components/BuildingTypes';

const Container = Styled.View`
  background-color: ${colors.gray.background};
  justify-content: space-between;
  flex: 1;
`;

const MainSection = Styled.View`
`;

const LineContainer = Styled.View`
  padding-horizontal: 16px;
`;

const SubmitSection = Styled.View`
  padding-horizontal: 16px;
`;

const InstructionSection = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const InstructionText = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.orange.status};
`;

const PrepareInventoryPageContent = ({inventory}) => {
  const {navigator, params} = useNavigation();
  const [selectedHomeType, setSelectedHomeType] = useState({sizes: []});
  const {form, submitting, handleSubmit} = useInlineFormMutation({
    name: 'prepareInventoryProjectForm',
    form: {
      inventoryId: inventory.id,
      size: inventory.project.size,
    },
    mutation: gql`
      mutation usePrepareInventoryMutation($prepareInventoryProjectForm: PrepareInventoryProjectForm!) {
        response: prepareInventoryProject(prepareInventoryProjectForm: $prepareInventoryProjectForm) {
          ${gql.errors}
          inventory {
            id
          }
        }
      }
    `,
    onSuccess: () => {
      navigator.navigate('StartInventoryPage', {uuid: params.uuid});
    },
    onError: (errors) => {
      console.log({errors});
    },
  });
  const field = 'prepareInventoryProjectForm.size';
  const projectSize = _.get(form.values, 'prepareInventoryProjectForm.size');

  return (
    <Container>
      <MainSection>
        <ModalScreenHeader
          title={'Move Size'}
          right={<CloseButton onPress={() => navigator.goBack()} />}
        />
        <Space height={16} />
        <BuildingTypes
          selectedHomeType={selectedHomeType}
          setSelectedHomeType={setSelectedHomeType}
          form={form}
        />
        <Space height={4} />
        {selectedHomeType.sizes.length > 0 && (
          <React.Fragment>
            <LineContainer>
              <Line />
            </LineContainer>
            {!projectSize && (
              <React.Fragment>
                <Space height={12} />
                <InstructionSection>
                  <Space width={16} />
                  <Icon color={colors.orange.status} size={13} source={Icon.ExclamationCircle} />
                  <Space width={6} />

                  <InstructionText>Please select size below as well to continue.</InstructionText>
                </InstructionSection>
              </React.Fragment>
            )}
            <Space height={12} />
            <BuildingSizes sizeOptions={selectedHomeType.sizes} form={form} field={field} />
          </React.Fragment>
        )}
      </MainSection>
      <SubmitSection>
        <LargeButton
          disabled={!selectedHomeType.name || !projectSize || submitting}
          onPress={handleSubmit}
        >
          <LargeButton.Text color={colors.gray.background}>Submit</LargeButton.Text>
        </LargeButton>
        <Space height={32} />
      </SubmitSection>
    </Container>
  );
};

const PrepareInventoryPage = () => {
  const {params} = useNavigation();
  const {loading, data} = useQuery(PrepareInventoryPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.uuid,
    },
  });

  return (
    <Loading loading={loading}>
      {() => <PrepareInventoryPageContent inventory={data.inventory} />}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PrepareInventoryPage.query = gql`
  query PrepareInventoryPage($uuid: String!) {
    ${gql.query}

    inventory(uuid: $uuid) {
      id
      project {
        id
        size
      }
    }
  }
`;

export default PrepareInventoryPage;
