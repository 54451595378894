/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import CompleteInventoryTutorialForm from '@shared/modules/Inventory/forms/CompleteInventoryTutorialForm';
import useCompleteInventoryTutorialMutation from '@shared/modules/Inventory/hooks/useCompleteInventoryTutorialMutation';
import FullScreenModal from 'components/FullScreenModal';
import LargeButton from 'components/LargeButton';
import {RecordRoomVideo} from 'modules/Inventory/Help/InventoryTutorial/assets';
import TutorialHeader from 'modules/Inventory/Help/InventoryTutorial/components/TutorialHeader';
import TutorialSteps from 'modules/Inventory/Help/InventoryTutorial/components/TutorialSteps';

const ContentContainer = Styled.View`
  background-color: ${colors.gray.background};
`;

const ButtonSection = Styled.View`
  padding-horizontal: 16px;
`;

const UploadInventoryTutorialInventoryContent = ({inventory, isOpen, onClose}) => {
  const completeInventoryTutorialForm = CompleteInventoryTutorialForm.new(inventory, {
    kind: 'RECORD_ROOM',
  });
  const {form, handleSubmit} = useCompleteInventoryTutorialMutation({
    completeInventoryTutorialForm,
    onSuccess: () => {},
    onError: (errors) => {
      console.log({errors});
    },
  });

  const {inventoryTutorial} = inventory.project.organization;

  const completeTutorial = () => {
    if (
      inventoryTutorial.completedOn === 'ON_STEP' &&
      !_.get(form, 'values.completeInventoryTutorialForm.hasCompletedInventoryTutorial')
    ) {
      form.setFieldValue('completeInventoryTutorialForm.hasCompletedInventoryTutorial', true);
      handleSubmit();
    }
  };

  const isTutorialCompleted =
    form.values.completeInventoryTutorialForm.hasCompletedInventoryTutorial;

  return (
    <FullScreenModal
      isOpen={isOpen}
      onClose={() => {
        onClose(form.values);
      }}
    >
      <ScrollView style={{flex: 1}}>
        <TutorialHeader title={inventoryTutorial.title} />
        <ContentContainer>
          <Space height={28} />
          <TutorialSteps
            tutorialSteps={inventoryTutorial.steps}
            videoFile={RecordRoomVideo}
            completeTutorial={completeTutorial}
          />
          <Space height={12} />
          <ButtonSection>
            <LargeButton
              disabled={!isTutorialCompleted}
              onPress={() => {
                onClose(form.values);
              }}
            >
              <LargeButton.Text color={colors.white}>
                {isTutorialCompleted ? 'Got it!' : 'Please finish tutorial video to continue'}
              </LargeButton.Text>
            </LargeButton>
          </ButtonSection>
          <Space height={32} />
        </ContentContainer>
      </ScrollView>
    </FullScreenModal>
  );
};

const UploadInventoryTutorialInventoryModal = ({isOpen, onClose}) => {
  const {params} = useNavigation();
  const {loading, data} = useQuery(UploadInventoryTutorialInventoryModal.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.uuid,
      inventoryTutorialKind: 'RECORD_ROOM',
    },
  });

  return (
    <Loading loading={loading}>
      {() => (
        <UploadInventoryTutorialInventoryContent
          inventory={data.inventory}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UploadInventoryTutorialInventoryModal.query = gql`
  ${CompleteInventoryTutorialForm.new.fragment}

  query ClosetInventoryTutorialInventoryPage(
    $uuid: String!,
    $inventoryTutorialKind: String!,
  ) {
    ${gql.query}

    inventory(uuid: $uuid) {
      id
      project {
        id
        hasCompletedInventoryTutorial(kind: $inventoryTutorialKind)
        customer {
          id
        }
        organization {
          id
          inventoryTutorial: inventoryTutorialForKind(kind: $inventoryTutorialKind) {
            title
            completedOn
            steps {
              kind
              text
              number
              isCompleteStep
            }
          }
        }
      }
      ...CompleteInventoryTutorialForm_new
    }
  }
`;

export default UploadInventoryTutorialInventoryModal;
