/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import PhotoCollection from 'components/PhotoCollection';

const ClosetSectionContainer = Styled.View`
  padding-horizontal: 16px;
`;

const ClosetHeader = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const NoClosetButton = Styled.Touchable`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const NoClosetButtonCheckbox = Styled.View`
  height: 20px;
  width: 20px;
  border-width: 1px;
  border-radius: 2px;
  border-color: ${(props) =>
    props.isChecked
      ? colors.blue.interactive
      : props.showWarning
        ? colors.red.warning
        : colors.blue.accent};
  background-color: ${(props) => (props.isChecked ? colors.blue.interactive : colors.white)};
  align-items: center;
  justify-content: center;
`;

const NoClosetButtonText = Styled.H7`
  color: ${colors.blue.interactive};
  ${fontWeight(700)}
`;

const ClosetWarningSection = Styled.View`
`;

const ClosetWarningContainer = Styled.View`
  align-items: center;
  background-color: ${colors.red.accent};
  border-radius: 4px;
  padding-vertical: 10px;
  padding-horizontal: 16px;
`;

const ClosetWarningText = Styled.H8`
  color: ${colors.red.warning};
  ${fontWeight(500)}
`;

const SectionTitleContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const SectionTitleText = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const SectionTitle = ({children, icon, size}) => {
  return (
    <SectionTitleContainer>
      <Icon color={colors.gray.primary} size={size} source={icon} />
      <Space width={8} />
      <SectionTitleText>{children}</SectionTitleText>
    </SectionTitleContainer>
  );
};

const RoomClosetPhotos = ({
  room,
  form,
  closetTutorialModal,
  fileViewerHandleOpenRef,
  onUploadStart,
  onUploadSuccess,
  onUploadError,
}) => {
  const {canSkipPhotos} = form.values.customerRoomForm;
  const hasErrors = !!Object.keys(form.errors).length;
  const showWarning = hasErrors && !room.filteredAttachments.length;
  return (
    <ClosetSectionContainer>
      <Space height={16} />
      <ClosetHeader>
        <SectionTitle icon={Icon.DoorClosed} size={15}>
          Closet
        </SectionTitle>
        {room.roomType.isPhotoRequired && (
          <NoClosetButton
            onPress={() => form.setFieldValue('customerRoomForm.canSkipPhotos', !canSkipPhotos)}
          >
            <NoClosetButtonCheckbox isChecked={canSkipPhotos} showWarning={showWarning}>
              <Icon color={colors.white} size={14} source={Icon.Check} />
            </NoClosetButtonCheckbox>
            <Space width={8} />
            <NoClosetButtonText>I don't have a closet here</NoClosetButtonText>
          </NoClosetButton>
        )}
      </ClosetHeader>
      {!canSkipPhotos ? (
        <React.Fragment>
          <Space height={8} />
          <PhotoCollection
            room={room}
            onUploadStart={onUploadStart}
            onUploadSuccess={onUploadSuccess}
            onUploadError={onUploadError}
            onAddPhotoPress={(fileViewerHandleOpen) => {
              if (room.inventory.project.hasCompletedInventoryTutorial) {
                fileViewerHandleOpen();
              } else {
                fileViewerHandleOpenRef.current = fileViewerHandleOpen;
                closetTutorialModal.handleOpen();
              }
            }}
          />
          {showWarning ? (
            <React.Fragment>
              <ClosetWarningSection>
                <Space height={8} />
                <ClosetWarningContainer>
                  <ClosetWarningText>
                    {_.get(form, 'errors.customerRoomForm.photos')}
                  </ClosetWarningText>
                </ClosetWarningContainer>
              </ClosetWarningSection>
              <Space height={8} />
            </React.Fragment>
          ) : (
            <Space height={16} />
          )}
        </React.Fragment>
      ) : (
        <Space height={16} />
      )}
    </ClosetSectionContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

RoomClosetPhotos.fragment = gql`
  ${PhotoCollection.fragment}
  fragment RoomClosetPhotos on Room {
    id
    filteredAttachments(attachmentCategoryKinds: ["ROOM_PHOTO"]) {
      id
    }
    inventory {
      id
      project {
        id
        hasCompletedInventoryTutorial(kind: $inventoryTutorialKind)
      }
    }
    roomType {
      id
      isPhotoRequired
    }
    ...PhotoCollection
  }
`;

export default RoomClosetPhotos;
